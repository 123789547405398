.inputBox{
    display: flex;
    position: relative;
}
.inputBox input{
    /* border-radius: none; */
    display: block;
    width: 100%;
    padding: 0.5rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #007A97;
    background-color: #fff;
    background-clip: padding-box;
    border: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    box-shadow: 0px 1px 5px 1px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.inputBox button{
    padding: 1px;
}
.inputBox input:focus,
.inputBox input::placeholder ,
.inputBox button{
    color: #007A97;
}
.inputBox button:focus,
.inputBox button:hover{
    color: #007697;
    box-shadow: none;
}
.toggleShow{
    position: absolute;
    right: 30px;
    top: 50%;
    transform: translateY(-50%);
}
.status{
    position: absolute;
    right: 8px;
    font-size: 1.5rem;
    top: 50%;
    transform: translateY(-50%);
}

.terms{
    color: #007A97;
    font-size: 10px;
    padding-left: 1.5rem ;
}
.termsLink{
    color :#146275;
    font-size: 12px;
    font-family: 'qanelas_softbold';
    text-decoration: none;
}
.checkBoxBtn,
.radio{
    appearance: none;
    -webkit-appearance: none;
    background-color: #fff;
    width: 36px;
    height: 36px;
    cursor: pointer;
    position: relative;
}
.radio{
    /* outline: 1px solid #007A97; */
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.5);
    border-radius: 4px;
    width: 11px;
    height: 11px;
}
.checkBoxBtn:checked,
.radio:checked{
    background-color: #007A97;
}
.checkBoxBtn:checked::before,
.radio:checked::before{
    content: '';
    position: absolute;
    bottom: 10px;
    background: #fff;
    left: 5px;
    width: 10px;
    height: 2px;
    transform: rotate(45deg);
    transform-origin: top;

}

.radio:checked::before{
    left: 2px;
    width: 3px;
    bottom: 3px;
    height: 1px;
}
.checkBoxBtn:checked::after,
.radio:checked::after{
    content: '';
    position: absolute;
    bottom: 15px;
    background: #fff;
    left: 7.5px;
    width: 25px;
    height: 2px;
    transform: rotate(-44deg);
    transform-origin: top;

}
.radio:checked::after{
    left: 2.5px;
    transform: rotate(-45deg);
    width: 6px;
    bottom: 4px;
    height: 1px;
}

.submitBtn{
    border-radius: 10px;
    font-size: 1.5rem;
    text-transform: uppercase;
}

.fs_1_2{
    font-size: 1.2rem;
}

.outlineRed{
    outline: 3px solid red;
}

.sectionWrapper .content{
    width: 50%;
    text-align: center;
    margin-bottom: 2rem;
}
.textCreme{
    color: #e8b078;
}
.sectionWrapper .content p{
    margin: 0;
    font-size: 1.2rem;
}
.sectionWrapper .imageSide{
    width: 50%;
}
.sectionWrapper .image{
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    object-fit: cover;
}

.label{
    font-family: 'qanelas_softregular';
}


.imageTextWrapper{
    position: absolute;
    width: 45%;
    margin: 0.5rem;
    text-align: end;
  }

  .imageTextWrapperCenter{
    position: absolute;
    width: 50%;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    text-align: center;
  }


  .bottomLogo{
    position: absolute;
    bottom: 15px;
    left: 25%;
    transform: translateX(-50%);
  }

@media (min-width:768px) {
    .checkBtnContainer{
        flex-basis: 0.4;
    }
    .terms{
        flex-basis: 0.6;
    }
}

@media(max-width:767px){
    .sectionWrapper{
        flex-direction: column-reverse;
    }
    .sectionWrapper .content{
        width: 100%;
        text-align: center;
        
    }
    .sectionWrapper .imageSide{
        width: 100%;
    }
    .sectionWrapper .image{
        position: relative;
        width: 100%;
        max-height: 260px;
    }
    .bottomLogo{
        /* left: 50%; */
        display: none;
    }
    .bottomLogoCenter{
        display: block;
        position: absolute;
        bottom: 15px;
        left: 50%;
        transform: translateX(-50%);
    }
    .imageTextWrapperCenter{
        position: absolute;
        width: 100%;
        left: 0;
        top: 5%;
        z-index: 1;
        right: 0;
        transform: translateY(0%);
      }
  }

  @media (max-width:400px){
    .label{
        font-size: 0.95rem;
    }
  }

  @media (max-width:350px){
    /* .submitBtn{
        width: 60% !important;
    } */
    .label{
        font-size: 0.65rem;
    }
  }

