.navPillsContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    padding-top: 5px;
}

.navPill{
    padding: 0.5rem;
    text-align: center;
    cursor: pointer;
    color: #000;
    text-transform: uppercase;
    text-decoration: none !important;
}
.navPill:hover{
    color: #000;
}
.navPill.active{
    background-color: #fff;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.tabContent{
    background-color: #fff;
    min-height: 90vh;
    display: flex;
    padding: 1rem;
    justify-content: center;
}

/* balance  */
.balanceWrapper{
    width: 100%;
    max-width:700px;
}

.balanceContentContainer{
    width: 100%;
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(2,50%);
}

.balanceCard{
    text-align: center;
    color: #fff;
    width: 100%;
}
.walletCard{
    font-family: 'qanelas_softregular';
    text-transform: uppercase;
    background-size: 140%;
    background-position: center bottom;
    background-repeat: no-repeat;
    padding: 1rem;
    border-radius: 20px;
}

.walletCard h2{
    position: relative;
    font-weight: 700;
    font-size: 40px;
    display: inline;
}
.walletCard h2::before,
.walletCard h2::after{
    content: '';
    position: absolute;
    width: 110%;
    background-color: #fff;
    top: 0;
    left: -5px;
    height: 1px;
}
.walletCard h2::after{
    top: unset;
    bottom: 0;
}

.walletCard p{
    font-size: 12px;
    margin: 0;
}

.walletCard button{
    border-radius: 10px;
    text-transform: uppercase;
}

.topUpCard{
    background-color: #E0FAFD;
    padding: 1rem;
    border-radius: 20px;
}
.topUpCard h3{
    text-transform: uppercase;
    font-size: 25px;
}
.topUpCard input{
    width: 250px;
}
.topUpCard button{
    border-radius: 10px;
    text-transform: uppercase;
}



.recentCard{
    padding: 1rem;
    border-radius: 20px;
    background-color: #00A7E1;
}

.transaction{
    display: flex;
    /* align-items: center; */
    font-size: 12px;
    color: #000;
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 0.5rem;
    white-space: nowrap;
}

.tDate{
    /* background-color: #E0FAFD; */
    padding: 9px 10px;

}
.tInfo{
    background-color: #fff;
    padding: 9px 5px;
    flex-grow: 1;
    overflow: hidden;
}
.tInfo p{
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.tDown{
    background-color: #BFBFBF;
    padding: 0 10px;
    display: flex;
    align-items: center;
}
.toolTipContainer{
    width: 60vw;
    text-align: justify;
}

@media (min-width:768px){
    .navPill{
        min-width: 150px;
    }
    .toolTipContainer{
        width: 50%;
    }
}

@media (max-width:767px) {
    
    .balanceContentContainer{
        grid-template-columns: 100%;
    }
    .navPill{
        min-width: 120px;
    }
}

@media (max-width:350px){
    .tInfo{
        font-size: 10px;
    }
    .navPill{
        min-width: 100px;
    }
    .topUpCard h3{
        font-size: 20px;
    }
}

/* account */



.bgDarkBlue{
    background-color: #007A97;
}

.accountWrapper{
    width: 100%;
    max-width:1000px;
    min-height: 100%;
    background-color: #fff;
}

.topBanner{
    position: relative;
    background-position: center;
    /* min-height: 100px; */
    overflow: hidden;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}
.topBanner img{
    object-fit: cover;
    width: 100%;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
}
.bannerOverlay{
    position: absolute;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0.4;
    z-index: 1;
}
.container{
    padding: 0 100px;
}

.userBanner{
    display: flex;
    align-items:center ;
    justify-content: space-between;
    gap: 20px;

    /* flex-wrap: wrap; */
    margin: 2rem 0 3rem;
    padding: 1rem 2rem;
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
}
.userInfo{
    flex-basis: 60%;
}
.userNameWrapper{
    display: flex;
    align-items: baseline;
    column-gap: 10px;
}
.userName{
    text-transform: capitalize;
}
.userType{
    font-size: 13px;
    white-space:nowrap;
}

.userDescription{
    font-size: 14px;
    line-height: normal;
}
.userActions{
    text-align: center;
}
.userActions div{
    margin: 1rem 0;
}

.userImgWrapper{
    width: 130px;
    height: 130px;
    border-radius: 50%;
    border: 4px solid #BFBFBF;
    overflow: hidden;
    position: relative;
}
.userImgWrapper img{
    width: 100%;
    height: 100%;
}

.accountBtn{
    background: #2CC9E6;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.5);
    color: #fff !important;
    border-radius: 10px;
    text-transform: uppercase;
    font-size: 12px;
    min-width: 120px
}

.address{
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: 'qanelas_softregular';
}

.bottomContent{
    display: flex;
    margin-top: 3rem;
    column-gap: 30px;
    margin-bottom: 2rem;
}
.bottomContentBox{
    padding: 1rem 2rem 2rem;
    border-radius: 20px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.postListBox{
    flex-basis: 60%;
}
.rightContainer{
    flex-basis: 40%;
}

.accordion_item{
    border: none;
    margin-bottom: 0.5rem;
}

.post button{
    width: 100%;
    height: 40px;
    display: flex;
    font-family: 'qanelas_softregular';
    padding: 0;
    color: #000 !important;
    line-height: normal;
    border: none !important;
    
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
    /* align-items: center; */
    justify-content: space-between;
}
.post button::after{
    /* display: none; */
    position: absolute;
    right: 10px;
}

/* .post button:not(.collapsed)::after{
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
 } */


.pDate{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-basis: 30% ;
    height: 100%;
    /* min-width: 100px; */
    text-align: center;
    flex-shrink: 0;
}
.pInfo{
    display: flex;
    align-items: center;
    flex-grow: 1;
    max-width: 80%;
}

.pInfo p{
    margin: 0;
    padding-left: 1rem;
    white-space: nowrap;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}
.pDown{
    width: 40px;
    height: 100%;
    background-color: #BFBFBF;
    font-size: 1.5rem;
    flex-shrink: 0;
}

.post .pDate,
.post .pInfo,
.post .pDown{
    padding: 5px;
} 




.notification{
    line-height: normal;
    font-family: 'qanelas_softregular';
    margin-bottom: 0.5rem;
}
.notification::first-letter{
    text-transform: uppercase;
}

.countWrapper{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2px 0;
    color: #4C4E5F;
}
.countInfo{
    display: flex;
    align-items: baseline;
    column-gap: 10px;
}
.countInfo .count{
    font-size: 30px;
}
.countInfo .count.comingSoon{
    font-size: 7px !important;
}

.countInfo .countFor{   
    text-transform: uppercase;
    color: #212121;
    font-size: 22px;
    font-family: 'qanelas_softregular';
}


@media(max-width:991px){
    .userActions{
        width: 100%;
    }
    .bottomContent{
        flex-direction: column;
       }
    .userBanner{
        flex-wrap: wrap;
    }
    .rightContainer{
        margin-top: 3rem;
    }
}

@media (max-width:767px){  
    .container{
        padding: 0 10px;
    }
    .userBanner{
        flex-direction: column;
    }
    /* .pInfo{
        max-width: 50%;
    } */
}

.inputLable{
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    cursor: pointer;
    background-color: rgba(0, 0, 0,0.5);
    width: 100%;
    height: 100%;
}

.editIcon{
    cursor: pointer;
}
.editInputField{
    appearance: none;
    border-radius: 10px;
    resize: none;
    padding:  5px;
    border: 1px solid rgb(118, 118, 118);
    box-shadow: none;
    outline: none;
}

.typeInputField{
    max-width: 100px;
}

@media (max-width:370px){  
    .typeInputField{
        max-width: 55px;
    }
}


/* vv */
.action_row{
    border: 1px solid #fff;
    font-family: 'qanelas_softregular';
    background-color: #E0FAFD;
    margin: 0;
}
.action_row > div{
    padding: 0;
}
.action_headWrapper{
    background-color: rgb(44, 201, 230,0.5);
    padding: 10px !important;
    display: flex;
    align-items: center;
}
.action_head_title{   
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: 'qanelas_softregular';
    margin: 0;
    font-weight: 700;
    font-size: 17px;
    text-transform: capitalize;
}
.action_des{
    font-size:14px ;
}
.action_des_editBox{
    resize: none;
    min-height: 40px;
    height: fit-content;
    width: 100%;
    outline: none;
    box-shadow: none;
    padding: 10px;
    overflow-y: scroll;
    font-size: 14px;
    border-radius: 10px;
}
.action_des_editBox::-webkit-scrollbar{
    display: none;
}
.action_loction,
.action_wadd{
    font-size: 17px;
    font-weight: 700;
    text-overflow: ellipsis;
    overflow: hidden;
}
.action_imgWrapper{
    display: inline-block;
    width: 40px;
    height: 30px;
    border-radius: 5px;
    overflow: hidden;
}
.action_bottom{
    background-color: #2CC9E6;
    padding: 10px;
}
.action_bottom button{
    border-radius: 20px;
}


/* image cropper popup*/
.imageCropperBox{
    border-radius: 10px;
}