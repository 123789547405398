.connector_content{
    display: grid;
    grid-template-rows: repeat(2,1fr);
    grid-template-columns: repeat(2,1fr);
    place-items: center;
    
    padding: 1.5rem 0;
}

@media (max-width:767px) {
    .connector_content{
        grid-template-rows: repeat(auto,1fr);
        grid-template-columns: repeat(1,1fr);
    }
}

.connector_btn{
    padding: 1rem 0.5rem;
    background: transparent;
    outline: none;
    border: none;
}
@media (min-width:768px){
    .connector_btn:nth-child(2),
    .connector_btn:nth-child(3){
        border: 1px solid rgba(195, 195, 195, 0.14);
    }
    .connector_btn:nth-child(2){
        border-right: none;
        border-top: none;
    }
    .connector_btn:nth-child(3){
        border-left: none;
        border-bottom: none;
    }
}

@media (max-width:767px){
    .connector_btn{
        border-bottom: 1px solid rgba(195, 195, 195, 0.14);
    }
    .connector_btn:nth-child(4){
        border: none;
    }
}
