/* .background{
   
} */



.formInput{
  border: none !important;
  text-align: center;
  border-radius: 20px;
}

.formInput.border{
  border: 1px solid red !important;
}

.checkbox::before{
  border-radius: 2px;
}

.number::-webkit-inner-spin-button,
.number::-webkit-outer-spin-button {
  appearance: none;
  -webkit-appearance: none;
}


.darkBlueBg{
  background-color: #007A97;
  color: #fff;
  font-family: 'qanelas_softregular';
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.darkBlueBg span {
  /* font-family: 'qanelas_softbold'; */
  font-weight: 600;
}


.darkBlueBg p{
  line-height: 1.5;
  font-size: 1.2rem;
}
.darkBlueBg h3{
  font-size: calc(1.3rem + .5vw);
  font-weight: 500;
  font-family: 'qanelas_softregular';
}
.wrapper{
  position: relative;
}

.bottom_border{
  border-bottom: 10px solid #fff;
}
.borderTop{
  border-top: 10px solid #fff;
}

.wrapper > img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}

.termsLink{
  color :#172DEB;
  text-decoration: underline;
}

.h2{
  font-size:calc(1.325rem + .9vw) !important;
}

.noScroll{
  overflow-y: hidden !important;
  max-height: none !important;
}

.noWrap{
  white-space: nowrap;
}

@media(min-width:767px){
  .mdSticky{
    position: sticky;
    top: 20px;
  }
}

@media (max-width:571px) {
  .successTitle{
    font-size: 1.45rem;
  }
}
@media (max-width:350px) {
  .successTitle{
    font-size: 1.25rem;
  }
}