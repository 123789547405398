

.bodyContent{
  background: #E0FAFD;;
    /* background: ;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.05);
    border-radius: 5px; */
    font-family: 'qanelas_softbold';
  } 

  .height-50{
    height: 50px;
  }
  .pageContent h4{
    font-family: 'qanelas_softbold';
    font-size: 32px;
    line-height: 187.5%;
    color: #007A97;
  }

  .btnall{
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.1);
    color: #fff;
    border: 0;
    font-size: 20px;
  }

  .bgBlue{
    background: #007A97;
  }

  .userImg {
    position: relative;
}
.fontSize16{
  font-size: 1.1rem;
}
.paymeSec input,
.paymeSec select{
  border: 0;
}
.currencyAmount{
  font-size: 20px;
}
.paymeSec input:focus,
.paymeSec select:focus{
  box-shadow: none;
  border: 0;
  outline: none;
}
.currencySymbol{
  width: 24px;
  height: 24px;
  background: rgba(232, 176, 120, 1);
  color: #fff;
  text-align: center;
  border-radius: 100px !important;
}
.currencySelector{
  font-size: 20px;
}
.currencySelector option{
  font-size: 16px;
}
.currencyAddon{margin: 0 !important}
 .userImg .userStatus {
    width: 10px;
    height: 10px;
    background-color: #adb5bd;
    border-radius: 50%;
    border: 1px solid #fff;
    position: absolute;
    right: 0;
    left: auto;
    bottom: 5px;
}
.shadowCustom{
  box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.1);
}
.userImg.online .userStatus {
  background-color: #248562;
}
.avatarXs {
  height: 2.2rem;
  width: 2.2rem;
}


.iconsSec{
  border-radius: 5px;
  background: rgba(253, 250, 246, .6);
}
.iconsSec img{
  width: 24px;
}
  .mainC{
    /* background:url("/images/waves-large.svg") no-repeat; */
    background-size: cover;
    min-height: 100vh;
    border-radius:0 0 5px 5px;
    background-position: top center;
    
  }
  .connectWalletBtn{
    background: #FFFFFF;
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    font-family: 'qanelas_softbold';
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 160%;
    padding:10px 20px;
    text-align: center;
    width: 208px;
    height: 50px;
    color: #000000;
  }

  .btnCheck:focus+.btn, .btn:focus {
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgb(232 176 120 / 25%);
}

  .connectWalletBtn span{
    position: relative;
    top: 2px;
  }
  .blueColor{
    color: #007A97;
  }

  .otherC {
    display: flex;
    justify-content: space-between;
    background: rgba(253, 250, 246, 0.6);
    background-size: cover;
    border-radius: 0;
    height: 95px;
    padding: 28px 45px;
  }
  .otherC h3{
    font-family: 'qanelas_softbold';
    font-style: normal;
    font-size: 20px;
    line-height: 187.5%;
    color: #007A97;
    margin: 0;
    padding: 0;
  }

  .otherC h3 span{color: #e8b078;}
  .infoSec{text-align: right;}
  .infoSec h2{
    font-family: 'qanelas_softbold';
    font-style: normal;
    font-weight: bold;
    font-size: 60px;
    line-height: 115%;
    
    color: #007A97;

  }

  .infoSec p{
    font-family: 'qanelas_softbold';
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 160%;
    text-align: right;
    color: #000000;
      }
.footerC{
  font-family: 'qanelas_softbold';
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 15px;
  text-align: center;
  color: #000000;
  margin-bottom: 44px;
}

.orangeColor{
  background: rgba(232, 180, 130, 1) !important;

}

.dropdown_menu{
  transform: translate(0,31px) !important;
  right: 0 !important;
  left: unset !important;
}

.checkbox[type="checkbox"]::before {
  background: #dee2e6ad;
}
.checkbox[type="checkbox"]::after {
  border-color: transparent #dee2e6ad #dee2e6ad transparent;
}
.checkbox[type="checkbox"]:checked::after {
  border-color: transparent white white transparent;
}

@media (min-width: 991px){
 
  .mainC{height: calc(100vh - 192px);}
 
    .otherC {
      height: 79px;
      padding: 21px 25px;
  }
  .content{
    padding-left: 0.625rem;
  }
}
@media (max-width: 991px) {
  .cButtonSec{
    margin: 1rem 0;
  }
  .connectWalletBtn,
  .c-button-sec{width: 100%;}
  header{
    padding: 20px 0 0 0;
  }
  .mainC {
    padding: 0 !important;
}
.chatUserList{
  width: 100%;
}
.content{
  padding: 0 2rem;
}
/* .dropdown_menu{
  transform: translate(0px,0px) !important;
} */
}


@media (max-width: 750px) {
  .bodyContent{
    margin: 15px 0;
  }
}
  @media (min-width: 768px) {

    .mainC{
      padding: 77px 77px 0 77px;
    }
    /* header{
      padding: 60px 77px 0 77px;
    } */
    
  }

  @media (max-width: 600px) {
    .infoSec h2{font-size: 52px;}
    .infoSec p{font-size: 18px;}
    .mb480{
      margin-bottom: 15px;
    }
  }
  
  @media (max-width: 990px) {
    .iconsSec{
      margin: 20px 0;
    }
    .iconsSec img{
      margin: 0 15px;
    }
  }

  @media (max-width: 480px) {
    .infoSec h2{font-size: 41px;}
    .infoSec h2 br,
    .infoSec p br{display: none;}
  }

  @media (max-width: 393px) {
    .infoSec h2{font-size: 32px;}
    .infoSec h2 br,
    .infoSec p br{display: none;}

  }

  @media (max-width:767px){.mobile_responsive_menu{
    /* transform: translateX(-87px) !important; */
    left: -111px!important;
    right:unset !important;
  }}

/* .suggestion_list{
  position: absolute;
  display: none;
  background-color: #fff;
  width: 100%;
  z-index: 10;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
} */

/* .suggestion_item{
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  cursor: pointer;
} */
.suggestion_item:hover{
  background-color: rgba(0, 0, 0, 0.05);
}
.suggestion_item:last-child{
  border:none;
}

.download:hover{
  color: #fff;
}

/* qr code */
.qrCodeWrapper{
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.qrCodeAmount{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}

/* beachpay new css */
.pageContent{
  position: relative;
} 
.topBannerImg{
  /* width: 100%; */
  /* object-fit: cover; */
  /* height: 250px; */
  max-width: 100%;
  height: auto;
  width: 100%;
  object-fit: cover;
  object-position: 0 -85px;
  height: 215px;
  
}

#BeachTab{

  /* position: absolute;
  top: 100px;
  left : 0;
  width:100%; */
  margin-top: -60px;
}

.topBanner{
  /* position: absolute;
  top: 0;
  padding: 1rem ;
  width: 100%;
  left: 0; */
  /* margin-bottom: 30px; */
}

.userDetailsWrapper{
  color:  #fff;
  padding:  0 20px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  justify-content: space-between;
}
.userInfo{
  display: flex;
  column-gap: 10px;
  text-transform: uppercase;
  align-items: center;
}
.userInfo h2{
  font-weight: 400;
  font-family: 'qanelas_softregular';
}
.userBalance{
  font-family: 'qanelas_softregular';
  font-size: 1.3rem;
}
.userImgWrapper{
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
}
.userImgWrapper img{
  width: 100%;
  height: 100%;
}
.userBalance{
  text-align: right;
}
.userLink{
  color: #fff !important;
  text-decoration: none !important;
}
.headingTitle{
  position: relative;
  text-align: center;
  color: #fff;
  text-transform: uppercase;
  line-height: normal;
  margin: 20px 0;
}

.headingTitle::before,
.headingTitle::after{
  content: '';
  position: absolute;
  top: -10px;
  background-color: #fff;
}

.headingTitle::before{
  left: 0;
  right: 0;
  height: 1px;
}

.headingTitle::after{
  left: 50%;
  transform: translateX(-50%);
  height: 4px;
  top: -11px;
  min-width: 100px;
  max-width: 30%;
  border-radius: 2px;
}

/* tabbanner */

.tabBanner{
  /* display: flex;
  justify-content: space-between; */
  align-items: center;
  padding: 1.5rem 2rem;

  position: relative;
  /* column-gap: 10px; */
}
.tabBanner h6{
  line-height: normal;
  font-size: 15px;
}

.tabBannerText{
  /* flex-grow: 1; */
  margin: 0 auto;
  max-width: 700px;
  /* text-align: center; */
  line-height: normal;
  color: #007A97;
}

.tabBannerImgWrapper{
  position: absolute;
  right: 20px;
  top:50%;
  transform: translateY(-50%);
  width: 100px;
  display: flex;
  align-items: center;
}

.tabBannerImg{
  width: 100%;
  border-radius: 5px;
}

.tabContentBox{
  background-color: #E0FAFD;
  padding: 1rem 2rem;
}

.CenterBox{
  max-width: 700px;
  margin: 0 auto;
}

/* home tab */

.ContactsWarapper,
.transactionsWrapper{
    min-height: 100px;
}

.ContactsWarapper p,
.transactionsWrapper p{
    color: #007A97;
}

.contactListWrapper{
  overflow: hidden;
  overflow-x: scroll;
}
.contactListWrapper::-webkit-scrollbar{
  display: none;
}
.contactList{
  display: flex;
  column-gap:10px ;
}
.contactWrapper{
  display: inline-block;
  cursor: pointer;
  /* width: 100px; */
  text-align: center;
}
.contactWrapper .contactImgWrapper{
  width: 60px;
  height: 60px;
  margin: 0 auto;
  border-radius: 50%;
  overflow: hidden;
}
.contactWrapper .contactImgWrapper img{
  width: 100%;
  border-radius: 50%;
  height: 100%;
}
.contactWrapper p{
  color: #007A97;
  margin: 0;
}


/* recent */
.RecentListWrapper{
  padding: 0 10px;
}
.transaction{
  align-items: center;
  font-family: 'qanelas_softregular';
  background-color: #fff;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
  margin-bottom: 5px;
  color: #007A97;
  border-radius: 10px;
  padding: 10px 0;
}

.transaction p{
  line-height: normal;
  white-space: nowrap;
}

.recentText{
  overflow: hidden;
  text-overflow: ellipsis;
}

.moreBtn{
  padding: 0;
  margin: 10px;
  box-shadow: none !important;
  font-size: 1rem;
  color: #007A97 !important;
}

/* scan tab */
.scanTab .tabBannerImgWrapper{
  width: 60px;
  height: 60px;
}

.scanTab .tabBannerImg{
    cursor: pointer;
}

.mainContent{
  margin: 15px 0;
}

.qrCodeBox{
  background-color: #fff;
  border-radius: 10px;
  padding: 10px;
  font-family: 'qanelas_softbold',sans-serif;
  
  text-align: center;
  box-shadow: 0px 4px 30px rgba(0, 122, 151, 0.3);
}
.qrCodeBoxHeading{
  color: #007A97;
  font-weight: 700;
  font-family: 'qanelas_softbold',sans-serif;
  position: relative;
  text-transform: uppercase;
}
.qrCodeBoxHeading span{
  color: #e8b078;
}
.userNameAt{ 
  font-family: 'qanelas_softBold',sans-serif;
  text-transform: uppercase;
  font-weight: 700;
}
.userQrcode{
  width: 100%;
  border-radius: 20px;
  /* margin-top: -70px; */
}
.qrBoxBtnsWrapper{
  border-radius: 10px;
  margin-top: 15px; 
  overflow: hidden;
  border: 1px solid #fff;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
}
.qrBoxBtn{
  width: 50%;
  display: inline-flex;
  /* justify-content: space-around; */
  justify-content: center;
  padding:10px 15px;
  column-gap: 10px;
  align-items: center;
  font-size: 20px;
  text-transform: uppercase;
  color: #fff;
  border: none;
}

.qrBoxDwnBtn{
  background-color: #005164;
  border-right: 1px solid #fff;
}
.qrBoxSndBtn{
  background-color: #007A97;
  border-left: 1px solid #fff;
}

.scanTab .moreBtn{
  font-size: 17px;
}

/* qr scanner */
.qrScannerWrapper{
  width: 100%;
  max-width: 260px;
  margin: 0 auto;
  position: relative;
}

.qrScanner{
  width: 100%;
}

.qrScanner div{
  border: none !important;
  box-shadow: none !important;
}

.qrScannerPlaceHolderWrapper{
  padding-top: 100%;
  position: relative;
}

.scannerPlaceHolder{
  position: absolute;
  text-align: center;
  text-transform: uppercase;
  color: #007A97;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}

.qrScannerLine{
  position: absolute;
  background-color: #007A97;
  height: 1px;
  width: 100%;
  transition-property: top;
  transition-duration: 300ms;
  animation: scanning 5s infinite ease;
}

@keyframes scanning{
    0%{
        top: 0;
    }
    50%{
      top: 100%;
    }
    100%{
      top: 0;
    }
}

.qrScannerBorder{
    position: absolute;
    background-color: #007A97;
    width: 100px;
    height: 10px;
    border-radius: 10px;
}


.qrScannerBorderTopLeft{
  top: 0;
  left: 0;
}
.qrScannerBorderTopLeft::after{
  position: absolute;
  content: '';
  height: 100px;
  width: 10px;
  background-color: #007A97;
  top: 0;
  left: 0;
  border-bottom-left-radius:10px ;
  border-bottom-right-radius:10px ;
}
.qrScannerBorderTopRight{
  top: 0;
  right: 0;
}
.qrScannerBorderTopRight::after{
  position: absolute;
  content: '';
  height: 100px;
  width: 10px;
  background-color: #007A97;
  top: 0;
  right: 0;
  border-bottom-left-radius:10px ;
  border-bottom-right-radius:10px ;
}
.qrScannerBorderBottomLeft{
  bottom: 0;
  left: 0;
}
.qrScannerBorderBottomLeft::after{
  position: absolute;
  content: '';
  height: 100px;
  width: 10px;
  background-color: #007A97;
  bottom: 0;
  left: 0;
  border-top-left-radius:10px ;
  border-top-right-radius:10px ;
}
.qrScannerBorderBottomRight{
  bottom: 0;
  right: 0;
}
.qrScannerBorderBottomRight::after{
  position: absolute;
  content: '';
  height: 100px;
  width: 10px;
  background-color: #007A97;
  bottom: 0;
  right: 0;
  border-top-left-radius:10px ;
  border-top-right-radius:10px ;
}

/* send tab */
.sendTab .tabBannerImgWrapper{
  width: 50px;
  height: 50px;
}
.sendInputBoxWrapper{
  position: relative;
  margin: 70px auto 0px;
  max-width: 400px;
  color: #007A97;
  font-family: 'qanelas_softregular';
}
.sendInputBox{
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid rgba(0, 122, 151,0.5);
  display: flex;
  align-items: center;
  padding: 0 10px;
  width: 100%;
  margin:  0 auto;
}
.suggestion_list{
  position: absolute;
  left: 0;
  background-color: #fff;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  border: 1px solid rgba(0, 122, 151,0.5);
  border-top-width: 0px;
  width: 100%;
  display: none;
  max-height: 200px;
  overflow-y: scroll;
  max-width: 400px;
  margin:  0 auto;
}

.suggestion_list::-webkit-scrollbar-track
{
	border: none;
}
.suggestion_list::-webkit-scrollbar
{
	width: 5px;
	background-color: transparent;
}
.suggestion_list::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	background-color: rgba(0, 122, 151,0.6);
}

.suggestion_item{
  padding: 10px;
  text-overflow: ellipsis;
  overflow: hidden;
  margin: 0;
  cursor: pointer;
  /* border-bottom: 1px solid rgba(0, 122, 151,0.5); */
}
.sendInput{
  border: none;
  color: #007A97;
  font-family: 'qanelas_softregular';
  appearance: none;
  font-size: 1rem;
  height: 50px;
  flex-grow: 1;
}
.sendInput::placeholder{
  color: #007A97;
}
.sendIcon{
  width: 20px;
}

.errMsg{
  max-width: 400px;
  margin: 1rem auto;
  font-size: 12px;
  line-height: normal;
  text-align: center;
}

.nextBtnWrapper{
  margin-top: 100px;
  text-align: center;
}

.sendTabBtn{
  font-family: 'qanelas_softregular';
  text-transform: uppercase;
  border-radius: 10px;
  min-width: 100px;
  color: #fff !important;
}

.sendTabBtn:disabled{
  background-color: #e8b078 !important;
}

.BtnDarkLightBlue{
  background-color: #00A7E1;
}
.BtnDarkBlue{
  background-color: #007A97;
}
.BtnLightBlue{
  background-color: #2CC9E6;
}

.backBtn{
  display: inline-flex;
  align-items: center;
  color: #fff !important;
  font-size: 12px;
  font-family: 'qanelas_softregular';
  padding: 5px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  background-color: #00A7E1;
  opacity: 0.6;
  transition: opacity 300ms ease-in;
  text-transform: uppercase;
}
.backBtn:hover,
.backBtn:focus{
  opacity: 1;
}

.customAmountInputWrapper{
  position: relative;
  background-color: #fff;
  border-radius: 10px;
  color: #007A97;
  margin: 0 auto;
  font-size: 1.5rem;
  justify-content: center;
  max-width: 400px;
  display: flex;
  align-items: center;
  column-gap: 5px;
  border: 1px solid rgba(0, 122, 151,0.5);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
}
.customNumberInput{
  min-width: 30px;
  max-width: 110px;
  color: #007A97;
  height: 50px;
  width: 20px;
  appearance: none;
  border: none;
  text-align: center;
}
.inputBtn{
  padding: 0;
  box-shadow: none !important;
  color: #007A97;
}
.inputBtnUp{
  position: absolute;
  right: 10px;
  top: 5px;
}
.inputBtnDown{
  position: absolute;
  right: 10px;
  bottom: 5px;
}

.paymentAmt{
  font-size: 14px;
}

.receiverBoxWrapper{
    max-width: 400px;
    margin: 0 auto;
    position: relative;
    font-family: 'qanelas_softregular';
}
.receiverBox{
  position: relative;
  z-index: 1;
  background-color: #fff;
  border-radius: 10px;
  color: #007A97;
  margin: 0 auto;
  font-size: 1.5rem;
  justify-content: space-between;
  max-width: 400px;
  display: flex;
  overflow: hidden;
  border: 1px solid rgba(0, 122, 151,0.5);
  /* box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25); */
}
.receiverBoxInput{
  appearance: none;
  padding: 0 10px;
  flex-grow: 1;
  max-width: 350px;
  color: #007A97;
  text-align: center;
  border: none;
  font-size: 1rem;
  height: 50px;
  font-family: 'qanelas_softregular';
}
.receiverBoxInput::placeholder{
  color: #007A97;
  /* font-size: 14px; */
}
.receiverBoxDropBtn{
  width: 50px;
  height: 50px;
  flex-shrink: 0;
  padding: 0;
  box-shadow: none !important;
  /* display: inline-flex;
  justify-content: center;
  align-items: center; */
  color: #007A97;
  background-color: #E0FAFD;
}
.receiverBoxWrapper .suggestion_list,
.sendInputBoxWrapper .suggestion_list{
  margin-top: -10px;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25); 
  color: #007A97;
}

.payConcern{
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 10px;
  color: #007A97;
  margin: 10px 0;
  font-family: 'qanelas_softregular';
}
.label{
  cursor: pointer;
}
.checkbox{
  cursor: pointer;
  border: none;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  appearance: none;
  background-color: #fff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.checkbox:checked{
  background-color: #007A97;
}

.codeCopyBtn{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem 0 1rem;
  font-size: 18px;
  column-gap: 10px;
}
.codeCopyBtnImg{
    width: 25px;
}

@media(min-width:768px){
  .tabBanner h6{
    text-align: center;
  }
  .userQrcode{
    margin-top: -25px;
  }
  .userNameAt{
    margin-top: -15px;
  }
  .qrBoxDwnBtn img{
    width: 25px;
  }
  .qrBoxSndBtn img{
    width: 35px;
  }
  .codeCopyBtn{
    font-size: 30px;
  }
 
  .transaction
  {
    padding: 7px 0;
  }
  .qrCodeBoxHeading{
    line-height: normal;
    margin-bottom: 5px;
  }
}

@media(max-width:991px){
  .tabBannerImgWrapper{
    width: 70px;
}
}



@media(max-width:767px){
  
  .pageContent{
    margin-top: -125px; 
  }
  .topBannerImg{
      /* height: 450px; */
     
  }
  .userDetailsWrapper{
    padding: 0 10px;
  }
  .userInfo{
    column-gap: 5px;
  }
  .headingTitle{
    font-size: 1.5rem;
  }
  .tabBannerText{ 
    text-align: left;
  }
  .transaction p{
    font-size: 14px;
  }
  .userQrcode{
    margin-top: -30px;
  }
  .userNameAt{
    margin-top: -20px;
  }
  .qrBoxDwnBtn img{
    width: 18px;
  }
  .qrBoxSndBtn img{
    width: 22px;
  }
  .qrBoxBtn{
    padding: 10px;
    font-size: 14px;
  }
  .sendTab .tabBannerImgWrapper{
    width: 50px;
    height: 50px;
  } 
  .sendInput{
    font-size: 12px;
  }
  .paymentAmt{
    font-size: 12px;
  }
}
@media(max-width:500px){
  #BeachTab{
    margin-top: 30px;
  }
  .topBannerImg{
    object-fit: cover;
  }
  .userInfo h2{
    font-size: 18px !important;
    font-weight: 600;
  }
  .userBalance p{
    /* margin: 0; */
    font-size: 1rem;
    line-height: normal;
  }
  .userBalance p .sendTabBtn{
    font-size: 0.8rem !important;
  }
  .topBannerImg{
    object-position: 0 0;
  }
  .tabBanner{
    padding: 2rem 1rem;
  }
  .tabBannerImgWrapper{
    right: 16px;
    width: 60px;
}
.headingTitle{
  font-size: 1.2rem;
}
.scanTab .tabBannerImgWrapper{
  width: 50px;
  height: 50px;
}
.userImgWrapper{
  width: 30px;
  height: 30px;
}

.userDetailsWrapper,
.userDetailsWrapper h5{
  font-size: 14px;
}

.contactWrapper .contactImgWrapper{
  width: 70px;
  height: 70px;
}
.qrCodeBoxHeading{
  font-size: 22px;
}
}
@media(max-width:400px){
    .tabBannerText{
      font-size: 13px;
    }
    .transaction p{
      font-size: 12px;
    }
    .sendInputBoxWrapper{
      margin: 70px -10px 0;
    }
    
}
@media(min-width:425px){
  .tabBanner h6{
    font-size: 17px;
  }
}
@media (max-width:500px){
  .tabBannerHome h6{
    max-width: 80%;
}
 
.homeTabBrTag{
  display: none;
}
}

@media(max-width:350px){
   
    .tabBannerText{
      font-size: 12px;
    }
    .tabBannerImgWrapper{
        width: 50px;
    }
    .contactWrapper .contactImgWrapper{
      width: 58px;
      height: 58px;
    }
    .transaction p{
      font-size: 13px;
    }
    .qrScannerBorder{
      width: 80px;
    }
    .qrScannerBorder::after{
      height: 80px;
    }
    .receiverBoxInput{
      max-width: 210px;
    }
}

.selector{
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
}
.sendAmountInput{
  min-width: 75%;
  text-align: left;
}
@media screen and (min-width:768px) {
  .sendAmountInput{
    min-width: 90%;
  }
}